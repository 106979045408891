<template>
  <div class="container">
    <div class="row text_top">
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-3 xsBd">
        <div class="buttion" @click="mechanismSj">
          <i class="iconfont icon-jigou"></i>机构介绍
          <em class="iconfont icon-youjiantou"></em>
        </div>
        <div class="buttion" @click="leader">
          <i class="iconfont icon-bumenlingdao-sel"></i>领导介绍
          <em class="iconfont icon-youjiantou"></em>
        </div>
        <div class="buttion" @click="mechanism">
          <i class="iconfont icon-zhaozhuanjia"></i>专家介绍
          <em class="iconfont icon-youjiantou"></em>
        </div>
        <div class="buttion" @click="mechanismSetUp">
          <i class="iconfont icon-jigouguanli"></i>机构设置
          <em class="iconfont icon-youjiantou"></em>
        </div>
      </div>

      <div class="col-lg-5 col-md-5 col-sm-5 xsBd hidden-xs">
        <el-carousel trigger="click" arrow="never" class="bannerTop">
          <el-carousel-item v-for="item in bannerLst" :key="item.sort">
            <img :src="item.banner_img" class="img-responsive" />
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="col-xs-4 xsBd visible-xs">
        <el-carousel
          trigger="click"
          arrow="never"
          height="100px"
          indicator-position="none"
          class="bannerTop"
        >
          <el-carousel-item v-for="item in bannerLst" :key="item.sort">
            <img :src="item.banner_img" class="img-responsive" />
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 xsBd">
        <div class="gg_hx"><dd></dd></div>
        <div class="gg_list">
          <li class="gg_left">通知公告</li>
          <li class="gg_right" @click="notice">更多></li>
        </div>
        <div class="gg_text hidden-xs">
          <li v-for="item in noticeLst" :key="item" @click="noticeInfo(item.notice_id)">
            <span v-if="screenWidth <= 970">{{ item.notice_title.substring(0, 10) + "..." }}</span>
            <span v-else>{{ item.notice_title.substring(0, 20) + "..." }}</span>
            <i>{{ item.update_time }}</i>
          </li>
        </div>
        <div class="gg_text visible-xs">
          <li @click="noticeInfo(item.notice_id)" v-for="item in noticeLst.slice(0, 4)" :key="item">{{ item.notice_title.substring(0, 8) + "..."}}<i>{{ item.update_time }}</i></li>
        </div>
      </div>
    </div>
  </div>

  <!-- 广告位 -->
  <div class="container">
    <div class="row advertising">
      <div class="col-lg-6 col-md-6 col-sm-6 col-sm-6 col-xs-6 xsBd">
        <img :src="banner1" class="img-responsive" />
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-sm-6 col-xs-6 xsBd">
        <img :src="banner2" class="img-responsive" />
      </div>
    </div>
  </div>

  <!-- 信息动态 -->
  <div class="container">
    <div class="row advertising">
      <div class="col-lg-2 col-md-2 col-sm-2 col-sm-2 col-xs-3 xsBd xinxiImg">
        <img :src="xinxiImg" class="img-responsive" />
      </div>
      <div class="col-lg-10 col-md-10 col-sm-10 col-sm-10 col-xs-9 xsBd">
        <el-tabs v-model="activeName">
          <el-tab-pane label="最新动态" name="first"></el-tab-pane>
          <el-tab-pane label="新闻动态" name="second"></el-tab-pane>
          <el-tab-pane label="美育教育" name="third"></el-tab-pane>
        </el-tabs>
        <div class="move" @click="newsMoe">更多></div>
      </div>
    </div>
    <div class="row advertisingText" v-if="activeName == 'first'">
      <div class="col-lg-6 col-md-6 col-sm-6 col-sm-12 advertising-text"  v-for="item in newestList" :key="item" @click="newsInfo(item.news_id)">
        <li v-if="screenWidth <= 970"> {{ item.news_title.substring(0, 15) }}<i>{{ item.update_time }}</i></li>
        <li v-else> {{ item.news_title.substring(0, 20) }}<i>{{ item.update_time }}</i></li>
      </div>
    </div>
    <div class="row advertisingText" v-else-if="activeName == 'second'" >
      <div class="col-lg-6 col-md-6 col-sm-6 col-sm-12 advertising-text" v-for="item in newsLst" :key="item" @click="newsInfo(item.news_id)">
        <li v-if="screenWidth <= 970"> {{ item.news_title.substring(0, 15) }}<i>{{ item.update_time }}</i></li>
        <li v-else> {{ item.news_title.substring(0, 20) }}<i>{{ item.update_time }}</i></li>
      </div>
    </div>
    <div class="row advertisingText" v-else-if="activeName == 'third'">
      <div class="col-lg-6 col-md-6 col-sm-6 col-sm-12 advertising-text"  v-for="item in educationLst"  :key="item"  @click="newsInfo(item.news_id)">
        <li v-if="screenWidth <= 970"> {{ item.news_title.substring(0, 15) }}<i>{{ item.update_time }}</i></li>
        <li v-else> {{ item.news_title.substring(0, 20) }}<i>{{ item.update_time }}</i> </li>
      </div>
    </div>
  </div>

  <!-- 广告位 -->
  <div class="container">
    <div class="row advertising">
      <div class="col-lg-6 col-md-6 col-sm-6 col-sm-6 col-xs-6 xsBd">
        <img :src="banner3" class="img-responsive" />
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-sm-6 col-xs-6 xsBd">
        <img :src="banner4" class="img-responsive" />
      </div>
    </div>
  </div>

  <!-- 战略合作 -->
  <div class="container">
    <div class="row advertising">
      <div class="col-lg-2 col-md-2 col-sm-2 col-sm-2 col-xs-3 xsBd advertisingTop">
        <img :src="active" class="img-responsive" />
      </div>
      <div class="col-lg-10 col-md-10 col-sm-10 col-sm-10 col-xs-9 xsBd"></div>
    </div>
    <!-- <div class="row activityTop">
      <div class="col-lg-3 col-md-3 col-sm-3 col-sm-6 col-xs-6 activitybd xsBd" v-for="item in activityLst" :key="item.activity_id" @click="activityInfo(item.activity_id)">
        <li>
          <img :src="item.activity_img" class="img-responsive" />
          <dd>{{ item.activity_title }}</dd>
        </li>
      </div>
    </div> -->
    <div class="row advertisingText">
      <div class="col-lg-6 col-md-6 col-sm-6 col-sm-12 advertising-text"  v-for="item in strategyLst" :key="item" @click="newsInfo(item.news_id)">
        <li v-if="screenWidth <= 970"> {{ item.news_title.substring(0, 15) }}<i>{{ item.update_time }}</i></li>
        <li v-else> {{ item.news_title.substring(0, 20) }}<i>{{ item.update_time }}</i></li>
      </div>
    </div>
  </div>

  <!-- 在线平台 -->
  <div class="container">
    <div class="row advertising">
      <div class="col-lg-2 col-md-2 col-sm-2 col-sm-2 col-xs-3 xsBd">
        <img :src="zaixian" class="img-responsive" />
      </div>
      <div class="col-lg-10 col-md-10 col-sm-10 col-sm-10 col-xs-9 xsBd"></div>
    </div>
    <div class="row activityTop">
      <div class="col-lg-2 col-md-2 col-sm-2 col-sm-4 col-xs-4 pingtaibd xsBd" @click="queryShows">
        <img :src="techang" class="img-responsive center-block" />
        <dd>社会艺术教师查询</dd>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-sm-4 col-xs-4 pingtaibd xsBd"  @click="queryShows">
        <img :src="ceping" class="img-responsive center-block" />
        <dd>素质证书查询</dd>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-sm-4 col-xs-4 pingtaibd xsBd"  @click="queryShows">
        <img :src="shizi" class="img-responsive center-block" />
        <dd>人才职业能力查询</dd>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-sm-4 col-xs-4 pingtaibd xsBd" @click="examination">
        <img :src="kaoshi" class="img-responsive center-block" />
        <dd>线上测试</dd>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-sm-4 col-xs-4 pingtaibd xsBd" @click="examination">
        <img :src="chengji" class="img-responsive center-block" />
        <dd>成绩查询</dd>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-sm-4 col-xs-4 pingtaibd xsBd"  @click="queryShows">
        <img :src="suzhi" class="img-responsive center-block" />
        <dd>社会实践查询</dd>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from "@/http/api";
import banner1 from "@/assets/banner1@2x.png";
import banner2 from "@/assets/banner2@2x.png";
import banner3 from "@/assets/banner3@2x.png";
import banner4 from "@/assets/banner4@2x.png";
import xinxiImg from "@/assets/xinxi.png";
import active from "@/assets/active.png";
import zaixian from "@/assets/zaixian.png";
import chengji from "@/assets/c.png";
import ceping from "@/assets/p.png";
import shizi from "@/assets/s.png";
import suzhi from "@/assets/z.png";
import techang from "@/assets/t.png";
import kaoshi from "@/assets/k.png";

export default {
  name: "indexContent",
  components: {
    banner1,
    banner2,
    banner3,
    banner4,
    xinxiImg,
    active,
    zaixian,
    chengji,
    ceping,
    shizi,
    suzhi,
    techang,
    kaoshi,
  },

  data() {
    return {
      bannerLst: [],
      noticeLst: [],
      newestList: [],
      newsLst: [],
      educationLst: [],
      activityLst: [],
      strategyLst:[],
      banner1,
      banner2,
      banner3,
      banner4,
      xinxiImg,
      active,
      chengji,
      ceping,
      shizi,
      suzhi,
      techang,
      kaoshi,
      zaixian,
      activeName: "first",
      screenWidth: document.body.clientWidth,
    };
  },

  created() {
    this.bannerList();
  },

  methods: {
    bannerList() {
      post("/Index/indexData").then((res) => {
        this.bannerLst = res.data.bannerLst;
        this.noticeLst = res.data.noticeLst;
        this.newestList = res.data.newestList;
        this.newsLst = res.data.newsLst;
        this.educationLst = res.data.educationLst;
        this.strategyLst = res.data.strategy;
      });
    },

    handleClick() {
      this.bannerList();
    },

    //跳转关于我们
    about(){
      this.$router.push({ path: `/About` });
      this.$forceUpdate();
    },
     
     //跳转机构介绍
    mechanismSj(){ 
      this.$router.push({ path: `/mechanismset` });
      this.$forceUpdate();
    },

    //跳转领导介绍
    leader(){ 
      this.$router.push({ path: `/Leader` });
      this.$forceUpdate();
    },

    //跳转机构设置
    mechanismSetUp(){ 
      this.$router.push({ path: `/Mechanismsetup` });
      this.$forceUpdate();
    },


    //跳转专家介绍
    mechanism(){
      this.$router.push({ path: `/Mechanism` });
      this.$forceUpdate();
    },

    //跳转通知公告
    notice(){
      this.$router.push({ path: `/notice` });
      this.$forceUpdate();
    },

    //跳转通知详情
    noticeInfo(notice_id){
      this.$router.push({ path: `/noticeInfo/${notice_id}` });
      this.$forceUpdate();
    },

    //跳转信息动态更多
    newsMoe(){
      this.$router.push({ path: `/newsLst` });
      this.$forceUpdate();
    },
    
    //跳转新闻详情
    newsInfo(news_id){
      this.$router.push({ path: `/newsInfo/${news_id}` });
      this.$forceUpdate();
    },

    //跳转参演详情
    activityInfo(activity_id){
      this.$router.push({ path: `/activity/${activity_id}` });
      this.$forceUpdate();
    },

    //跳转成绩查询
    chaxun(){
      this.$router.push({ path: `/chaxun` });
      this.$forceUpdate();
    },

    //跳转测评证书查询
    cepingchaxun(){
      this.$router.push({ path: `/ceping` });
      this.$forceUpdate();
    },

    //跳转特长证书查询
    specialty(){
      this.$router.push({ path: `/specialtychaxun` });
      this.$forceUpdate();
    },
   
   teacher(){
     this.$router.push({ path: `/teacherchaxun` });
      this.$forceUpdate();
   },

   examiner(){
      this.$router.push({ path: `/examinerchaxun` });
      this.$forceUpdate();
    },

    examination(){
      this.$message({
          type: 'success',
          message: '系统开发中!'
        });
    },

    download(){
      this.$router.push({ path: `/download` });
      this.$forceUpdate();
    },

    achievement(){
      this.$router.push({ path: `/querysystem` });
        this.$forceUpdate();
    },
    
    yishuchaungzuo(){
      this.$router.push({ path: `/activityLst` });
      this.$forceUpdate();
    },

    queryShows(){
      this.$router.push({ path: `/querysystem` });
      this.$forceUpdate();
    },


  },
  

  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .text_top {
    margin-top: 2%;
  }
  .gg_hx dd {
    border-top: 1px solid #3467ac;
  }
  .gg_list {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 1%;
  }
  .gg_list li {
    list-style: none;
  }
  .gg_left {
    font-size: 0.9rem;
    color: #1a1a1a;
  }
  .gg_right {
    font-size: 0.9rem;
    color: #999999;
    cursor: pointer;
    padding-top: 2px;
  }
  .gg_text {
    margin-top: 1.5%;
  }
  .gg_text li {
    color: #333333;
    font-size: 0.9rem;
    line-height: 17px;
    cursor: pointer;
  }
  .gg_text li::marker {
    color: #c99538;
  }
  .gg_text li i {
    font-size: 0.8rem;
    color: #666666;
    font-style: normal;
    float: right;
  }
  .xsBd {
    padding-left: 5px;
    padding-right: 5px;
  }
  .buttion {
    cursor: pointer;
    border: 1px solid #3378cf;
    padding: 3px 0px;
    display: inline-flex;
    width: 100%;
    margin: 2px 0px;
    border-radius: 4px;
    justify-content: space-evenly;
    color: #296abc;
    font-size: 0.8rem;
  }
  .buttion:hover {
    background: #296abc;
    color: #ffffff;
  }
  .bannerTop {
    height: 100%;
  }
  /* :deep .el-carousel__container {position: relative; height:150px;} */
  :deep .el-tabs__item {
    padding: 0 10px;
    height: 35px;
    box-sizing: border-box;
    line-height: 35px;
    display: inline-block;
    list-style: none;
    font-size: 1rem;
    font-weight: 500;
    position: relative;
  }
  .xinxiImg {
    margin-top: 10px;
  }
  .advertisingTop {
    margin-top: 2%;
  }
  /* 广告位 */
  .advertising {
    margin-top: 1%;
  }
  /* 信息动态 */
  .advertisingText {
    margin-top: 0%;
  }
  .advertising-text {
    margin-top: 0.5%;
  }
  .advertising-text li {
    color: #333333;
    font-size: 1rem;
    cursor: pointer;
    line-height: 24px;
  }
  .advertising-text li i {
    font-size: 12px;
    color: #666666;
    font-style: normal;
    float: right;
  }
  .advertising-text li::marker {
    color: #c99538;
  }
  .move {
    position: absolute;
    right: 20px;
    top: 12px;
    font-size: 1rem;
    color: #999999;
  }
  /* 活动参展 */
  .activityTop {
    margin-top: 1%;
  }
  .activitybd li {
    border: 1px solid #04040429;
    list-style: none;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .activitybd li dd {
    font-size: 1.1rem;
    padding: 5px;
    color: #666666;
  }
  .pingtaibd {
    margin-top: 3%;
    cursor: pointer;
  }
  .pingtaibd img {
    width: 35%;
  }
  .pingtaibd dd {
    text-align: center;
    font-size: 1rem;
    padding-top: 2px;
    color: #4d4d4d;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

@media (min-width: 768px) {
  .text_top {
    margin-top: 2%;
  }
  .gg_hx dd {
    border-top: 2px solid #3467ac;
  }
  .gg_list {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 1%;
  }
  .gg_list li {
    list-style: none;
  }
  .gg_left {
    font-size: 16px;
    color: #1a1a1a;
  }
  .gg_right {
    font-size: 14px;
    color: #999999;
    cursor: pointer;
    padding-top: 2px;
  }
  .gg_text {
    margin-top: 0.5%;
  }
  .gg_text li {
    color: #333333;
    font-size: 14px;
    line-height: 26px;
    cursor: pointer;
  }
  .gg_text li::marker {
    color: #c99538;
  }
  .gg_text li i {
    font-size: 12px;
    color: #666666;
    font-style: normal;
    float: right;
  }
  .buttion {
    cursor: pointer;
    border: 1px solid #3378cf;
    padding: 9px 0px;
    display: inline-flex;
    width: 100%;
    margin: 5px 0px;
    border-radius: 4px;
    justify-content: space-evenly;
    color: #296abc;
    font-size: 12px;
  }
  .buttion:hover {
    background: #296abc;
    color: #ffffff;
  }
  .bannerTop {
    height: 100%;
  }
  :deep .el-carousel__container {
    position: relative;
    height: 217px;
  }

  /* 广告位 */
  .advertising {
    margin-top: 2%;
  }

  /* 信息动态 */
  .advertisingText {
    margin-top: 1%;
  }
  .advertising-text {
    margin-top: 0.5%;
  }
  .advertising-text li {
    color: #333333;
    font-size: 14px;
    cursor: pointer;
    line-height: 30px;
  }
  .advertising-text li i {
    font-size: 12px;
    color: #666666;
    font-style: normal;
    float: right;
  }
  .advertising-text li::marker {
    color: #c99538;
  }
  .move {
    position: absolute;
    right: 20px;
    top: 17px;
    font-size: 12px;
    color: #999999;
  }

  /* 活动参展 */
  .activityTop {
    margin-top: 1%;
  }
  .activitybd li {
    border: 1px solid #04040429;
    list-style: none;
    border-radius: 5px;
  }
  .activitybd li dd {
    font-size: 14px;
    padding: 5px;
    color: #666666;
  }
  .pingtaibd {
    margin-top: 1%;
    cursor: pointer;
  }
  .pingtaibd img {
    width: 40%;
  }
  .pingtaibd dd {
    text-align: center;
    font-size: 14px;
    padding-top: 3px;
    color: #4d4d4d;
  }
}

@media (min-width: 992px) {
  .text_top {
    margin-top: 2%;
  }
  .gg_hx dd {
    border-top: 2px solid #3467ac;
  }
  .gg_list {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 1%;
  }
  .gg_list li {
    list-style: none;
  }
  .gg_left {
    font-size: 16px;
    color: #1a1a1a;
  }
  .gg_right {
    font-size: 14px;
    color: #999999;
    cursor: pointer;
    padding-top: 2px;
  }
  .gg_text {
    margin-top: 0.5%;
  }
  .gg_text li {
    color: #333333;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
  }
  .gg_text li::marker {
    color: #c99538;
  }
  .gg_text li i {
    font-size: 12px;
    color: #666666;
    font-style: normal;
    float: right;
  }
  .buttion {
    cursor: pointer;
    border: 1px solid #3378cf;
    padding: 13px 10px;
    display: inline-flex;
    width: 100%;
    margin: 5px 0px;
    border-radius: 5px;
    justify-content: space-evenly;
    color: #296abc;
  }
  .buttion:hover {
    background: #296abc;
    color: #ffffff;
  }
  .bannerTop {
    height: 90%;
  }

  /* 广告位 */
  .advertising {
    margin-top: 2%;
  }

  /* 信息动态 */
  .advertisingText {
    margin-top: 1%;
  }
  .advertising-text {
    margin-top: 0.5%;
  }
  .advertising-text li {
    color: #333333;
    font-size: 14px;
    cursor: pointer;
    line-height: 30px;
  }
  .advertising-text li i {
    font-size: 12px;
    color: #666666;
    font-style: normal;
    float: right;
  }
  .advertising-text li::marker {
    color: #c99538;
  }
  .move {
    position: absolute;
    right: 20px;
    top: 17px;
    font-size: 12px;
    color: #999999;
  }

  /* 活动参展 */
  .activityTop {
    margin-top: 1%;
  }
  .activitybd li {
    border: 1px solid #04040429;
    list-style: none;
    border-radius: 5px;
  }
  .activitybd li dd {
    font-size: 14px;
    padding: 5px;
    color: #666666;
  }
  .pingtaibd {
    margin-top: 1%;
    cursor: pointer;
  }
  .pingtaibd img {
    width: 40%;
  }
  .pingtaibd dd {
    text-align: center;
    font-size: 14px;
    padding-top: 3px;
    color: #4d4d4d;
  }
}

@media (min-width: 1200px) {
  .text_top {
    margin-top: 2%;
  }
  .gg_hx dd {
    border-top: 2px solid #3467ac;
  }
  .gg_list {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 1%;
  }
  .gg_list li {
    list-style: none;
  }
  .gg_left {
    font-size: 16px;
    color: #1a1a1a;
  }
  .gg_right {
    font-size: 14px;
    color: #999999;
    cursor: pointer;
    padding-top: 2px;
  }
  .gg_text {
    margin-top: 0.5%;
  }
  .gg_text li {
    color: #333333;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
  }
  .gg_text li::marker {
    color: #c99538;
  }
  .gg_text li i {
    font-size: 12px;
    color: #666666;
    font-style: normal;
    float: right;
  }
  .buttion {
    cursor: pointer;
    border: 1px solid #3378cf;
    padding: 13px 10px;
    display: inline-flex;
    width: 100%;
    margin: 5px 0px;
    border-radius: 5px;
    justify-content: space-evenly;
    color: #296abc;
  }
  .buttion:hover {
    background: #296abc;
    color: #ffffff;
  }
  .bannerTop {
    height: 100%;
  }

  /* 广告位 */
  .advertising {
    margin-top: 2%;
  }

  /* 信息动态 */
  .advertisingText {
    margin-top: 1%;
  }
  .advertising-text {
    margin-top: 0.5%;
  }
  .advertising-text li {
    color: #333333;
    font-size: 14px;
    cursor: pointer;
    line-height: 30px;
  }
  .advertising-text li i {
    font-size: 12px;
    color: #666666;
    font-style: normal;
    float: right;
  }
  .advertising-text li::marker {
    color: #c99538;
  }
  .move {
    position: absolute;
    right: 20px;
    top: 17px;
    font-size: 12px;
    color: #999999;
  }

  /* 活动参展 */
  .activityTop {
    margin-top: 1%;
  }
  .activitybd li {
    border: 1px solid #04040429;
    list-style: none;
    border-radius: 5px;
  }
  .activitybd li dd {
    font-size: 14px;
    padding: 5px;
    color: #666666;
  }
  .pingtaibd {
    margin-top: 1%;
    cursor: pointer;
  }
  .pingtaibd img {
    width: 40%;
  }
  .pingtaibd dd {
    text-align: center;
    font-size: 14px;
    padding-top: 3px;
    color: #4d4d4d;
  }
}
</style>
