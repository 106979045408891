import { createStore } from 'vuex'
import { post } from "@/http/api";

export default createStore({
  state: {
    web_confs:1,
  },
  mutations: {
    increment(state){
      state.web_confs
    }
  },
  actions: {
  },
  modules: {
  },

  webConfs(){
    post( "/Index/webConfs").then((res) => {
        this.web_confs = res.webConfs;
    });
  },

  
})
