<template>
  <div class="container">
    <div class="row nav_top">
      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 nav_log">
        <img :src="web_confs.logo" class="img-responsive" />
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 nav_search hidden-xs">
        <el-input
          placeholder="请输入内容"
          v-model="search"
          class="input-with-select"
        >
          <template #append>
            <el-button icon="el-icon-search" @click="searchBottom"></el-button>
          </template>
        </el-input>
      </div>
      <!-- <div class="col-lg-2 col-md-2 col-sm-2 col-xs-4 header-pic">
        <img :src="headerImg" class="img-responsive pull-right" />
      </div> -->
    </div>
  </div>
  <div class="container-fluid">
    <div class="row nva_bc">
      <div class="container xs_bd">
        <div class="col-lg-5ths col-md-5ths col-sm-5ths col-xs-5ths nav_text">
          <li @click="home">网站首页</li>
        </div>
        <div v-for="item in cateLst" :key="item.cate_id" class="col-lg-5ths col-md-5ths col-sm-5ths col-xs-5ths nav_text">
          <li style="color: #ffff" @click="cate(item)">{{ item.cate_name }}</li>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from "@/http/api";
import headerImg from "@/assets/right.png";
import router from "../router/index";

export default {
  mode: 'history',
  name: "top",
  components: {
    headerImg,
  },

  data() {
    return {
      web_confs: {},
      headerImg,
      cateLst: [],
      input3:'',
      search:''
    };
  },

  created() {
    console.log(router.options.routes, 5555);
    this.webConfs();
    this.catelist();
  },

  methods: {

    searchBottom(){
      let search = this.search;
      if(!search){
        this.$message({
          type: 'warning',
          message: '请输入关键词!'
        });
        return false;
      }
     
      this.$router.push({ path: `/searchLst/${search}` });
      
      //  if(this.$route.name == 'searchLst'){
      //   location.reload();
      //    this.$forceUpdate();
         
      // }
    },

    webConfs() {
      post("/Index/webConfs").then((res) => {
        this.web_confs = res.webConfs;
      });
    },

    catelist() {
      post("/Index/cateLst").then((res) => {
        this.cateLst = res.cateLst;
      });
    },

    home() {
      this.$router.push('/')
    },

    cate(row){
      if(row.cate_type == 2){
        this.$router.push({ path: `/notice` });
        this.$forceUpdate();
      }

      if(row.cate_type == 1){
        this.$router.push({ path: `/newsLst` });
        this.$forceUpdate();
      }

      if(row.cate_type == 5){
        this.$router.push({ path: `/forum` });
        this.$forceUpdate();
      }

      if(row.cate_type == 3){
        this.$router.push({ path: `/evaluation` });
        this.$forceUpdate();
      }

      if(row.cate_type == 4){
        this.$router.push({ path: `/activityLst` });
        this.$forceUpdate();
      }

      if(row.cate_type == 6){
        this.$router.push({ path: `/download` });
        this.$forceUpdate();
      }

      if(row.cate_type == 7){ //关于我们
        this.$router.push({ path: `/About` });
        this.$forceUpdate();
      }

      if(row.cate_type == 8){ //在线考试
        this.$message({
          type: 'success',
          message: '系统开发中!'
        });
      }

      if(row.cate_type == 9){ //测评申报
        this.$router.push({ path: `/declare` });
        this.$forceUpdate();
      }

      if(row.cate_type == 10){ //查询系统
        this.$router.push({ path: `/querysystem` });
        this.$forceUpdate();
      }

      if(row.cate_type == 11){  //人力资源
        this.$router.push({ path: `/humanresourcesList` });
        this.$forceUpdate();
      }

      if(row.cate_type == 12){  //社会实践
        this.$router.push({ path: `/practiceList` });
        this.$forceUpdate();
      }

       if(row.cate_type == 13){  //评价报告
        this.$message({
          type: 'success',
          message: '系统开发中!'
        });
      }

    },

  },
};
</script>

<style scoped>
.nav_top {
  margin-top: 2%;
  margin-bottom: 1%;
}
.nav_search {
  margin-top: 2%;
}
.nav_search .el-input-group {
  line-height: normal;
  display: inline-table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-left: 10%;
}
.header-pic img {
  width: 50%;
}
.nav_log img {
  width: 70%;
}
.nva_bc {
  background: #3467ac;
  height: 85px;
}
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 0px;
  padding-left: 0px;
  padding-right: 0px;
  color: #ffff;
}
.nav_text {
  text-align: center;
  color: #ffff;
  line-height: 40px;
}
.nav_text li {
  list-style: none;
  cursor: pointer;
  font-size: 0.5rem;
  color: #ffff;
}

@media (max-width: 768px) {
  .nav_log img {
    width: 100%;
  }
  .col-sm-5ths {
    width: 14%;
    float: left;
  }
  .nva_bc {
    background: #3467ac;
    height: 60px;
  }
  .nav_text {
    text-align: center;
    color: #ffff;
    line-height: 26px;
    padding-top: 3px;
  }
  .nav_text li {
    list-style: none;
    cursor: pointer;
    font-size: 1rem;
    color: #ffff;
  }
  .xs_bd {
    padding-left: 2px;
    padding-right: 0px;
  }
}
@media (min-width: 768px) {
  .col-sm-5ths {
    width: 14%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-5ths {
    width: 14%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 14%;
    float: left;
  }
  .nav_text[data-v-13988cab] {
    text-align: center;
    color: #ffff;
    line-height: 28px;
    padding-top: 10px;
  }
}
</style>
