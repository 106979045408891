import axios from 'axios'
const $http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 30000 ,// request timeout
  });



// 添加请求拦截器
// $http.interceptors.request.use(function (config) {
//   config.headers.token = config.data;
//   return config;
// }, function (error) {
//   return Promise.reject(error);
// });



// 添加响应拦截器
$http.interceptors.response.use(function (response) {
  let data = response.data;
  return data;
}, function (error) {
  return Promise.reject(error);
});

export default $http