import { createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',//关于我们
    name: 'About',
    component: () => import( '../views/About.vue')
  },

  {
    path: '/notice',
    name: 'newsList',
    component: () => import( '../views/newsList.vue')
  },

  {
    path: '/newsLst',
    name: 'newsLst',
    component: () => import( '../views/newsLst.vue')
  },

  {
    path: '/newsInfo/:news_id',
    name: 'newsInfo',
    component: () => import( '../views/news.vue')
  },

  {
    path: '/noticeInfo/:notice_id',
    name: 'noticeInfo',
    component: () => import( '../views/notice.vue')
  },
  
  {
    path: '/forum',
    name: 'forum',
    component: () => import( '../views/forum.vue')
  },

  {
    path: '/evaluation',
    name: 'evaluation',
    component: () => import( '../views/evaluation.vue')
  },

  {
    path: '/activityLst',
    name: 'activityLst',
    component: () => import( '../views/activityLst.vue')
  },

  {
    path: '/activity/:activity_id',
    name: 'activity',
    component: () => import( '../views/activity.vue')
  },

  {
    path: '/download',
    name: 'download',
    component: () => import( '../views/download.vue')
  },

  {
    path: '/Mechanism',
    name: 'Mechanism',
    component: () => import( '../views/mechanism.vue')
  },

  {
    path: '/Leader',
    name: 'Leader',
    component: () => import( '../views/leader.vue')
  },

  {
    path: '/Mechanismsetup',
    name: 'Mechanismsetup',
    component: () => import( '../views/mechanismsetup.vue')
  },

  {
    path: '/declare', //测评申报
    name: 'declare',
    component: () => import( '../views/declare.vue')
  },
  
  {
    path: '/chaxun',
    name: 'chaxun',
    component: () => import( '../views/chaxun.vue')
  },

  {
    path: '/ceping',
    name: 'ceping',
    component: () => import( '../views/cepingchaxun.vue')
  },

  {
    path: '/specialtychaxun',
    name: 'specialtychaxun',
    component: () => import( '../views/specialtychaxun.vue')
  },

  {
    path: '/teacherchaxun',
    name: 'teacherchaxun',
    component: () => import( '../views/teacherchaxun.vue')
  },

  {
    path: '/examinerchaxun',
    name: 'examinerchaxun',
    component: () => import( '../views/examinerchaxun.vue')
  },

  { 
    path: '/querysystem',//查询系统
    name: 'querysystem',
    component: () => import( '../views/querysystem.vue')
  },

  {
    path: '/humanresourcesList', //人力资源
    name: 'humanresourcesList',
    component: () => import( '../views/humanresourcesList.vue')
  },

  {
    path: '/practiceList', //社会实践
    name: 'practiceList',
    component: () => import( '../views/practiceList.vue')
  },

  {
    path: '/searchLst/:search', //搜索结果
    name: 'searchLst',
    component: () => import( '../views/searchLst.vue')
  },

  {
    path: '/mechanismset/', //机构介绍
    name: 'searchLst',
    component: () => import( '../views/mechanismset.vue')
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router