<template>
  <top></top>
  <indexContent></indexContent>
  <bottom></bottom>
</template>

<script>
import Top from './top.vue'
import indexContent from './indexContent.vue'
import bottom from './bottom.vue'

export default {
  name: 'Home',
  components: {
    Top,
    indexContent,
    bottom,
  },

  

}
</script>
