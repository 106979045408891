<template>
  <div class="container-fluid">
    <div class="row bottom_bc">
        <div class="container">
            <div  class="col-lg-6 col-md-6 col-sm-6 col-xs-12 bottom_text xsBd">
              
              <li>{{web_confs.web_name}}</li>
              <li>地址：{{web_confs.address}} 电话：{{web_confs.tel}}</li>
              <li>备案号：<a href="https://beian.miit.gov.cn/" target="_blank" style="color:#fff">{{web_confs.icp}}</a></li>

            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 nav_text xsBd">
            
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { post } from "@/http/api";

export default {
  name: 'bottom',
  components: {
     
  },
   
   
 data() {
    return {
     web_confs:{},
    };
  },

  created() {
       this.webConfs();
  },

  methods: {
      webConfs(){
        post( "/Index/webConfs").then((res) => {
            this.web_confs = res.webConfs;
        });
      },
  },

}
</script>

<style scoped>
.bottom_bc{margin-top: 2%;background: #3467AC;height: 110px;}
.bottom_text{padding-top:2%;color: #FFFFFF}
.bottom_text li{line-height:25px;list-style: none;font-size: 13px;}
 @media (max-width:768px) {
   .xsBd{padding-left: 5px;padding-right: 5px;}
   .bottom_bc{margin-top: 2%;background: #3467AC;height: 110px;}
   .bottom_text{padding-top:4%;color: #FFFFFF}
   .bottom_text li{line-height:20px;list-style: none;font-size: 1rem;}
 }
</style>
